<template>
  <v-card elevation="0" class="py-0 my-0">
    <v-card-title>
      <v-row>
        <v-col>{{ $t('home.quality-issues.header') }}</v-col>
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            clearable
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      dense
      item-key="id"
      :loading="loading"
      :loading-text="$t('loading')"
      :headers="headers"
      :items="items"
      :items-per-page="5"
      :footer-props="{
        'items-per-page-options': [5, 10, 15],
        'items-per-page-text': $t('table.per-page')
      }"
      :no-data-text="$t('table.no-data')"
      :item-class="getItemClass"
      :options.sync="options"
      :server-items-length="total"
    >
      <template #[`item.status`]="{ item }">
        <div :class="item.status">
          {{ tStatus(item.status) }}
        </div>
      </template>
      <template #[`item.created_at`]="{ item }">
        <span>{{ date_to_str(item.created_at) }}</span>
      </template>
      <template #[`item.read_at`]="{ item }">
        <span>{{ date_to_str(item.read_at) }}</span>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-icon
          v-if="hasPermission('api.change_qcreport') && item.read_by == null"
          small
          @click="markAsRead(item)"
        >
          mdi-check
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import ProductionService from '@/services/ProductionService.js';

export default {
  components: {},

  data: function() {
    return {
      loading: false,
      refreshInterval: null,
      refreshOn: true,
      items: [],
      options: {},
      total: 0,
      search: '',
      searchTimeout: null
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('home.quality-issues.order'),
          value: 'order',
          sortable: true
        },
        {
          text: this.$t('home.quality-issues.machine'),
          value: 'machine',
          sortable: true
        },
        {
          text: this.$t('home.quality-issues.net'),
          value: 'quantity_net',
          sortable: true
        },
        {
          text: this.$t('home.quality-issues.gross'),
          value: 'quantity_gross',
          sortable: true
        },
        {
          text: this.$t('home.quality-issues.status'),
          value: 'status',
          sortable: true
        },
        {
          text: this.$t('home.quality-issues.comments'),
          value: 'comments',
          sortable: true
        },
        {
          text: this.$t('home.quality-issues.operator'),
          value: 'operator',
          sortable: true
        },
        {
          text: this.$t('home.quality-issues.date'),
          value: 'created_at',
          sortable: true
        },
        {
          text: this.$t('home.production-comments.read_by'),
          value: 'read_by',
          sortable: false
        },
        {
          text: this.$t('home.production-comments.read_at'),
          value: 'read_at',
          sortable: false
        },
        {
          text: this.$t('orders.actions'),
          value: 'actions',
          sortable: false
        }
      ];
    }
  },

  watch: {
    options: {
      async handler() {
        console.log('#options');
        await this.load(this);
      },
      deep: true
    },
    search: {
      handler() {
        console.log('#search');
        this.options.page = 1;
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.load(this);
        }, 500);
      }
    }
  },

  mounted() {
    console.log('mounted');
    this.load(this);
  },

  beforeDestroy() {
    clearInterval(this.refreshInterval);
    this.refreshInterval = null;
    this.refreshOn = false;
  },

  methods: {
    async load(self) {
      this.loading = true;
      console.log('loading quality issues');
      try {
        const options = {
          filters: [
            {
              field: 'statuses',
              operation: '=',
              value: 'rejected'
            },
            {
              field: 'statuses',
              operation: '=',
              value: 'conditionally+approved'
            }
          ],
          ...this.options,
          sortBy: ['read', 'created_at'],
          sortDesc: [false, true]
        };

        [this.items, this.total] = await ProductionService.getQualityIssues(
          options,
          this.search
        );
        this.items = this.items.map(this.transform);
        console.log('qc issues:', this.items);
        if (!self.refreshInterval && this.refreshOn) {
          self.refreshInterval = setInterval(() => {
            self.load(self);
          }, 60000);
        }
      } catch (error) {
        console.log('error:', error);
        this.showError(this, error);
      } finally {
        this.loading = false;
      }
    },

    async markAsRead(item) {
      try {
        console.log('markAsRead:', item);
        this.$swal({
          position: 'top-end',
          icon: 'success',
          html: '*The QC report is marked as read',
          showConfirmButton: false,
          timer: 1500
        });

        const updated = await ProductionService.QCMarkRead(item);

        if (updated.read_by?.length > 0) {
          await this.load(this);
        }
      } catch (err) {
        this.showError(this, err);
      }
    },

    transform(item) {
      return {
        ...item
      };
    },

    tStatus(status) {
      const key = `qc.statuses.${status}`;
      if (this.$te(key)) {
        return this.$t(key);
      }
      return status;
    },

    date_to_str(date) {
      if (!date) return '';
      return date.toLocaleString();
    },

    getItemClass: function(item) {
      if (item.read_by) return 'comment-read';
      else return 'comment-unread';
    }
  }
};
</script>

<style lang="sass" scoped>
.approved
    color: green
.conditionally
    color: orange
.rejected
    color: red
    font-weight: bold
</style>
