<template>
  <v-card elevation="0" class="mx-2">
    <status-card />
    <cardboard-demand />
    <quality-issues />
    <production-comments />
  </v-card>
</template>

<script>
import statusCard from '@/views/dashboard/components/orders/statusCard.vue';
import cardboardDemand from '../components/dashboard/cardboardDemand.vue';
import qualityIssues from '../components/dashboard/qualityIssues.vue';
import productionComments from '../components/dashboard/productionComments.vue';

export default {
  components: {
    statusCard,
    cardboardDemand,
    qualityIssues,
    productionComments
  },
  data: function() {
    return {};
  },

  computed: {},

  watch: {},

  mounted() {},
  methods: {}
};
</script>
