<template>
  <v-container cols="12" class="pl-1 pr-1 mt-5 mb-5">
    <v-row>
      <v-col cols="12" sm="6" md="4" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('panel.operation') }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ item.group_name }} /
              {{ item.operation_name }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('panel.started-at') }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ startedAt }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('panel.stopped-at') }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ stoppedAt }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('gross') }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ item.quantity_gross }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('net') }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ item.quantity_net }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    startedAt() {
      return this.dateFromISO8601(this.item.timeStart)?.toLocaleString() || '';
    },
    stoppedAt() {
      return this.dateFromISO8601(this.item.timeStop)?.toLocaleString() || '';
    }
  }
};
</script>

<style></style>
