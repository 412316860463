<template>
  <v-card elevation="0" class="py-0 my-0">
    <v-card-title>
      <v-row>
        <v-col>{{ $t('home.production-comments.header') }}</v-col>
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            clearable
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      dense
      :loading="loading"
      :loading-text="$t('loading')"
      :headers="headers"
      :items="items"
      :items-per-page="5"
      :footer-props="{
        'items-per-page-options': [5, 10, 15],
        'items-per-page-text': $t('table.per-page')
      }"
      :no-data-text="$t('table.no-data')"
      :single-expand="true"
      :expanded.sync="expanded"
      show-expand
      :options.sync="options"
      :server-items-length="total"
      :item-class="getItemClass"
      @click:row="expandRow"
    >
      <template #[`item.created_at`]="{ item }">
        <span>{{ date_to_str(item.created_at) }}</span>
      </template>
      <template #[`item.read_at`]="{ item }">
        <span>{{ date_to_str(item.read_at) }}</span>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-icon
          v-if="
            hasPermission('api.change_productionlog') && !item.comments_read
          "
          small
          @click.stop="markAsRead(item)"
        >
          mdi-check
        </v-icon>
      </template>
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <more-info :item="item" />
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Service from '@/services/ProductionLogService.js';
import moreInfo from './productionCommentsMoreInfo.vue';

export default {
  components: { moreInfo },

  data: function() {
    return {
      options: {},
      total: 0,

      loading: false,
      refreshInterval: null,
      refreshOn: true,
      items: [],

      expanded: [],
      search: '',
      searchTimeout: null
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('comments'),
          value: 'comments',
          sortable: false
        },
        {
          text: this.$t('orders.field.order_no'),
          value: 'order_no',
          sortable: false
        },
        {
          text: this.$t('home.production-comments.machine'),
          value: 'machine_name',
          sortable: false
        },
        {
          text: this.$t('home.production-comments.operator'),
          value: 'user',
          sortable: false
        },
        {
          text: this.$t('created_at'),
          value: 'created_at',
          sortable: false
        },
        {
          text: this.$t('home.production-comments.read_by'),
          value: 'read_by',
          sortable: false
        },
        {
          text: this.$t('home.production-comments.read_at'),
          value: 'read_at',
          sortable: false
        },
        {
          text: this.$t('orders.actions'),
          value: 'actions',
          sortable: false
        }
      ];
    }
  },

  watch: {
    options: {
      async handler() {
        console.log('#options');
        await this.load();
      },
      deep: true
    },
    search: {
      handler() {
        console.log('#search');
        this.options.page = 1;
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.load();
        }, 500);
      }
    }
  },

  beforeDestroy() {
    // console.log('carcboardDemand.beforeDestroy() ');
    clearInterval(this.refreshInterval);
    this.refreshInterval = null;
    this.refreshOn = false;
  },

  methods: {
    async load() {
      this.loading = true;
      console.log('loading production comments:', this.options);
      try {
        const options = {
          filters: [
            {
              field: 'with-comments',
              operation: '=',
              value: 'true'
            }
          ],
          ...this.options,
          sortBy: ['comments_read', 'created_at'],
          sortDesc: [false, true]
        };
        [this.items, this.total] = await Service.getProductionLog(
          options,
          this.search
        );
        console.log('logs:', this.items, ', total:', this.total);
        let self = this;
        if (!this.refreshInterval && this.refreshOn) {
          self.refreshInterval = setInterval(() => {
            self.load();
          }, 60000);
        }
      } catch (error) {
        console.log('error:', error);
        this.showError(this, error);
      } finally {
        this.loading = false;
      }
    },
    async markAsRead(item) {
      try {
        console.log('markAsRead:', item);
        this.$swal({
          position: 'top-end',
          icon: 'success',
          html: '*The comment is marked as read',
          showConfirmButton: false,
          timer: 1500
        });

        const updated = await Service.productionLogMarkRead(item);

        if (updated.comments_read === true) {
          await this.load();
        }
      } catch (err) {
        this.showError(this, err);
      }
    },
    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item];
    },
    date_to_str(date) {
      if (!date) return '';
      return date.toLocaleString();
    },
    getItemClass: function(item) {
      if (item.comments_read) return 'comment-read';
      else return 'comment-unread';
    }
  }
};
</script>

<style>
.comment-read {
  color: grey;
}
.comment-unread {
  color: rgb(45, 53, 65);
}
</style>
