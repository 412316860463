<template>
  <v-card elevation="0" class="py-0 my-0">
    <v-card-title>
      {{ $t('home.cardboard-demand.header') }}
    </v-card-title>
    <v-data-table
      dense
      item-key="cardboard"
      :loading="loading"
      :loading-text="$t('loading')"
      :headers="headers"
      :items="items"
      :items-per-page="5"
      :footer-props="{
        'items-per-page-options': [5, 10, 15],
        'items-per-page-text': $t('table.per-page')
      }"
      :no-data-text="$t('table.no-data')"
      :single-expand="true"
      :expanded.sync="expanded"
      show-expand
      @click:row="expandRow"
    >
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <more-info :item="item" :headers="headers" />
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import OrderService from '@/services/OrderService.js';
import moreInfo from './cardboardMoreInfo.vue';

export default {
  components: { moreInfo },

  data: function() {
    return {
      loading: false,
      refreshInterval: null,
      refreshOn: true,
      items: [],

      expanded: []
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('home.cardboard-demand.cardboard'),
          value: 'cardboard',
          sortable: true
        },
        {
          text: this.$t('home.cardboard-demand.num-orders'),
          value: 'order_count',
          sortable: true
        },
        {
          text: this.$t('home.cardboard-demand.num-sheets'),
          value: 'sheet_count',
          sortable: true
        }
      ];
    }
  },

  watch: {},

  mounted() {
    this.load();
  },

  beforeDestroy() {
    clearInterval(this.refreshInterval);
    this.refreshInterval = null;
    this.refreshOn = false;
  },

  methods: {
    async load() {
      this.loading = true;
      console.log('loading cardboard demand');
      try {
        this.items = await OrderService.getCardboardDemand();
        console.log('demand:', this.items);
      } catch (error) {
        console.log('error:', error);
        this.showError(this, error);
      } finally {
        this.loading = false;
        let self = this;
        if (!this.refreshInterval && this.refreshOn) {
          self.refreshInterval = setInterval(() => {
            self.load();
          }, 60000);
        }
      }
    },

    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item];
    }
  }
};
</script>
